/* .App {
  text-align: center;
} */

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

a {
  text-decoration: none;
  color: white;
}

.error {
  color: red;
}

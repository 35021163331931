.login-columns {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* flex-direction: row; */
}

.login-content {
  padding: 20vh 5rem;
  border: 1px solid #f0f0f2;
  box-shadow: 0 2px 6px rgba(0,0,0,.1);
  position: relative;
  z-index: 2;
  flex-basis: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  max-width: 360px;
}

.back-link {
  position: absolute;
  left: 4rem;
  top: 4rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
  cursor: pointer;
  color: #4f2d1b;
  letter-spacing: .012rem;
  font-weight: 600;
  font-size: 1.5rem;
}

.back-icon {
  margin-right: 10px;
}

.tree-logo {
  width: 100%;
  height: auto;
  margin-bottom: 6rem;
}

.react-tabs {
  width: 100%;
}

/* .tab-list-header {
  padding-bottom: 2rem;
} */

.react-tabs__tab-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* margin: 2rem, 0; */
  width: 100%;
  border-radius: 9999px;
  background: #fcfcfc;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.06), inset 0 3px 10px rgba(0,0,0,.07);
  position: relative;
  height: 42px;
  line-height: 42px;
}

.react-tabs__tab {
  width: 50%;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #292929;
  font-size: 16px;
  letter-spacing: .03rem;
  padding: 0 3rem;
  font-weight: 400;
}

.react-tabs__tab--selected {
  font-weight: 600;
  color: #487af6;

}

.slider-tab {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all .3s ease;
}

.slider-tab::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  background: #fff;
  border-radius: 999px;
  box-shadow: 0 1px 2px rgba(0,0,0,.06), 0 2px 22px rgba(0,0,0,.07);
}

.login-splash {
  color: #fff;
  background: linear-gradient(45deg,#7f9bd9,#6675e2);
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  line-height: 1.3;
  padding: 18.4rem 6rem 15.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.2rem;
  letter-spacing: -.026rem;
  font-weight: 500;
  color: hsla(0,0%,100%,.5);
}

@media (min-width: 769px) {
  .login-splash {
      line-height: 1.1875;
      padding: 24vh 9rem;
      width: 50%;
      font-size: 1.8rem;
      letter-spacing: -.013rem;
      color: hsla(0,0%,100%,.5);
  }
}

.login-splash-header {
  line-height: 1.3;
  color: #fff;
  font-size: 2.0rem;
  margin-bottom: 3.2rem;
  letter-spacing: .04rem;
  font-weight: 600;
}

@media (min-width: 769px) {
  .login-splash-header {
    font-size: 2.4rem;
    margin-bottom: 2rem;
    letter-spacing: .02rem;
  }
}

.login-form {
  padding-top: 2rem;
}

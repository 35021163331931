
.header {
  background-color: #282c34;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu {
  display: flex;
  flex-direction: row;
}

.menu-link {
  margin: 0 10px;
  font-size: 1.6rem;
  letter-spacing: .03rem;
  vertical-align: middle;
}

.button-signup {
  background: none;
  border-radius: 999px;
  font-weight: 500;
  padding: 1rem 2.2rem;
  position: relative;
  box-shadow: inset 0 0 0 2px #5181dc;
  transition: all .15s ease-in-out;
}

.button-signup:hover {
  background-color: blue;
  color: white;
}

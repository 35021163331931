.login-form {
  width: 100%;
}

.login-inputbox {
  margin-bottom: 2.4rem;
  box-sizing: content-box;
}

.login-input {
  color: #434343;
    background: #fcfcfc;
    border-radius: 9999px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.04), inset 0 1px 9px rgba(0,0,0,.05);
    border: 0;
    outline: 0;
    appearance: none;
    width: calc(100% - 6.4rem);
    height: 8.4rem;
    line-height: 8.4rem;
    font-size: 2.6rem;
    letter-spacing: .032rem;
    padding: 0 3.2rem;
    /* margin: 0 3.2rem; */
}

@media (min-width: 769px) {
  .login-input {
    height: 3.0rem;
    width: calc(100% - 4.2rem);
    line-height: 5.6rem;
    font-size: 1.25rem;
    letter-spacing: .011rem;
    padding: 0 2.1rem;
  }
}

.login-inputbox label {
    color: #6f6f6f;
    position: absolute;
    text-align: right;
    right: 0;
    top: 50%;
    font-weight: 400;
    margin-top: -2rem;
    font-size: 2.2rem;
    height: 4rem;
    line-height: 4rem;
    letter-spacing: .12rem;
    padding-right: 3rem;
    text-transform: uppercase;
}

.login-button {
  display: block;
  width: 100%;
  border-radius: 9999px;
  font-weight: 700;
  background: #bcc978;
  color: #fff;
  cursor: pointer;
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  margin-top: 24px;
  letter-spacing: .12px;
}

.login-button:active {
  background-color: #8da334; 
}

@media (min-width: 769px) {
  .login-form .login-button {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
    margin-top: 3.4rem;
    letter-spacing: .015rem;
  }
}

.login-form .submit-button .disabled {
    background: #ecf0f9;
    color: #a1abc2;
}

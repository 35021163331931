.appbar-tree-logo {
  height: 60px;
  width: auto;
}

.MuiExpansionPanelDetails-root-178 {
  flex-direction: column;
}

.currency-icon {
  margin-right: 20px;
}
